import { Route, Routes} from 'react-router-dom';
import MainPage from './mainpage.js';
import YoutubeHistoryReader from './youtubescript';
// import Creators from './youtube-history-reader/creators.js';
export default function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<MainPage />}/>
        <Route path="ythr" element={<YoutubeHistoryReader />} />
        {/* <Route path="creators" element={<Creators />} /> */}
      </Routes>
    </div>
  );
}