import "./ythr-styles.css";
import {Grid, ListItem} from '@mui/material';
import React, { useState } from 'react';
import { Box, shadows } from "@mui/system";
import Button from '@mui/material/Button';



export default function YoutubeHistoryReader(){
    const [gridItems, setGridItems] = useState([]);
    const [videoItems, setVideoItems] = useState([]);
    const [hideMain, setHideMain] = useState(false);
    const [videosEnabled, setVideosEnabled] = useState(false);
    const [creatorsEnabled, setCreatorsEnabled] = useState(false);
    function showFile(input) {
        setHideMain(true);
        let elements = document.getElementsByClassName("howTo");
        for(let i = 0; i < elements.length; i++){
            elements[i].hidden = true;
        }
        if(input){
            let file = input;
            let fr = new FileReader();
            fr.onload = receivedText;
            fr.readAsText(file);
        } 
    }
    
    function receivedText(e) {
        let lines = e.target.result;
        var newArr = JSON.parse(lines); 
        handleJSON(newArr)
    }
    
    function generatePage(data, sortedArray) {
        const parentElement = document.getElementById("main");
        const para = document.createElement("div");
        para.id = "mostWatched";
        const node = document.createTextNode("Here is your most watched creator:");
        para.appendChild(node);
        const creator = document.createElement("div");
        creator.id = "most-watched";
        const creatorText = document.createTextNode("Creator: " + sortedArray[0][0] + ". Videos watched: " + sortedArray[0][1] + ".");
        creator.appendChild(creatorText);
        para.appendChild(creator);
        const button = document.createElement("button");
        button.onclick = function(){showAll(sortedArray)};
        button.innerHTML = "Show All Creators";
        button.id = "showAll";
        const unavailableCheck = document.createElement("button");
        unavailableCheck.onclick = function(){changeHidden()};
        unavailableCheck.innerHTML = "Show All Hidden or Unavailable Videos";
        unavailableCheck.id = "unavailable";
        unavailableCheck.hidden = true;
        const mostWatchedVideos = document.createElement("button");
        mostWatchedVideos.onclick = function(){showVideos(data)};
        mostWatchedVideos.innerHTML = "Show Video Data";
        mostWatchedVideos.id = "showVideos";
        parentElement.append(para);
        parentElement.append(button);
        parentElement.append(unavailableCheck);
        parentElement.append(mostWatchedVideos);
    }
    
    function handleJSON(data){
        let dataArray = new Array();
        for(let i = 0; i < data.length; i++){
            dataArray[i] = new Array(2);
            dataArray[i][0] = null
            dataArray[i][1] = null
        }
        for(let i = 0; i < data.length; i++){
            // if(data[i].time[3] == '2'&& data[i].title.includes("Watched")){
            if(data[i].title.includes("Watched")){
                for(let k = 0; k < data.length; k++){
                    if(data[i].subtitles ){
                        if(dataArray[k][0] == null){
                            dataArray[k][0] = data[i].subtitles[0].name;
                            dataArray[k][1] = 1;
                            break;
                        }
                        else if(dataArray[k][0] == data[i].subtitles[0].name){
                            dataArray[k][1] += 1;
                            break;
                        }
                    } else {
                        if(dataArray[k][0] == null){
                            dataArray[k][0] = data[i].title;
                            dataArray[k][1] = 1;
                            break;
                        }
                        else if(dataArray[k][0] == data[i].title){
                            dataArray[k][1] += 1;
                            break;
                        }
                    }
                }
            }
        }
        dataArray.sort(function(a,b){if(a == null){
            return 1;
        }
        else if (b == null){
            return -1;
        }
        return b[1] - a[1]});
        let sortedArray = new Array();
        for(let i = 0; i < dataArray.length; i++){
            if(dataArray[i][0] != null){
                sortedArray[i] = dataArray[i];
            }
        }
        generatePage(data, sortedArray);
    }
    
    function showAll(data){
        setVideosEnabled(false);
        setCreatorsEnabled(true);
        document.getElementById("mostWatched").hidden = true;
        document.getElementById("showAll").hidden = true;
        document.getElementById("showVideos").hidden = false;
        if(document.getElementById("videos")){
            document.getElementById("videos").hidden = true;
        }
        document.getElementById("unavailable").hidden = false;
        var all = document.getElementById("all");
        if(all){
            all.hidden = false;
            var videos = document.getElementById("videos");
            if(videos){
                videos.hidden = true;
            }
        } else {
            const parentElement = document.getElementById("main");
            const para = document.createElement("div");
            const node = document.createTextNode("Here are your most watched creators:");
            para.appendChild(node);
            let items = [];
            for(let i = 0; i < data.length; i++){
                if(data[i][0].includes("https://www.youtube.com/watch?v=")){
                    items[items.length] = {text: "Video: " + data[i][0] + ". Times watched: " + data[i][1] + ".", id: items.length};
                    // setGridItems([...gridItems, {text: "Video: " + data[i][0] + ". Times watched: " + data[i][1] + ".", id: gridItems.length + 1}]);
                    // let creatorp = document.createElement("div");
                    // let creator = document.createElement("a");
                    // let creatorText = document.createTextNode("Video: " + data[i][0] + ". Times watched: " + data[i][1] + ".");
                    // creator.appendChild(creatorText);
                    // creator.title = "Video: " + data[i][0] + ". Times watched: " + data[i][1] + ".";
                    // creator.href = "https://web.archive.org/web/*/" + data[i][0].substring(8);
                    // creator.hidden = true;
                    // creator.className = "unavailableVideo";
                    
                    // creatorp.append(creator)
                    // para.appendChild(creatorp);
                } else {
                    items[items.length] = {text: "Creator: " + data[i][0] + ". Videos watched: " + data[i][1] + ".", id: items.length};
                    // let creator = document.createElement("div");
                    // let creatorText = document.createTextNode("Creator: " + data[i][0] + ". Videos watched: " + data[i][1] + ".");
                    // creator.appendChild(creatorText);
                    // para.appendChild(creator);
                }
                console.log(items);
            }
            setGridItems(items);
            console.log(gridItems);
            // para.id = "all";
            // parentElement.appendChild(para);
        }
    }

    function changeHidden(){
        if(document.getElementById("unavailable").innerHTML == "Show All Hidden or Unavailable Videos"){
            document.getElementById("unavailable").innerHTML = "Hide All Hidden or Unavailable Videos";
            let elements = document.getElementsByClassName("unavailableVideo");
            for(let i = 0; i < elements.length; i++){
                elements[i].hidden = false;
            }
        } else{
            document.getElementById("unavailable").innerHTML = "Show All Hidden or Unavailable Videos";
            let elements = document.getElementsByClassName("unavailableVideo");
            for(let i = 0; i < elements.length; i++){
                elements[i].hidden = true;
            }
        }
    }
    
    function showVideos(data){
        setVideosEnabled(true);
        setCreatorsEnabled(false);
        document.getElementById("mostWatched").hidden = true;
        document.getElementById("showAll").hidden = false;
        document.getElementById("showVideos").hidden = true;
        if(document.getElementById("all")){
            document.getElementById("all").hidden = true;
        }
        document.getElementById("unavailable").hidden = true;
        var videos = document.getElementById("videos");
        if(videos){
            videos.hidden = false;
            document.getElementById("all").hidden = true;
        } else {
            let dataArray = new Array();
            for(let i = 0; i < data.length; i++){
                dataArray[i] = new Array(4);
                dataArray[i][0] = null;
                dataArray[i][1] = null;
                dataArray[i][2] = null;
                dataArray[i][3] = null;
            }
            for(let i = 0; i < data.length; i++){
                if(data[i].time[3] == '2' && data[i].title.includes("Watched")){
                    if(data[i].title == "Watched a video that has been removed" && !data[i].subtitles){
                        
                    } else{
                        for(let k = 0; k < data.length; k++){
                            if(dataArray[k][0] == null){
                                dataArray[k][0] = data[i].title;
                                dataArray[k][1] = 1;
                                if(data[i].title.includes("https://www.youtube.com/watch?")){
                                    dataArray[k][2] = "https://web.archive.org/web/*/" + data[i].titleUrl;
                                    dataArray[k][3] = "Unknown Creator";
                                } else if(data[i].subtitles){
                                    dataArray[k][2] = data[i].titleUrl;
                                    dataArray[k][3] = data[i].subtitles[0].name;
                                }
                                break;
                            }
                            else if(dataArray[k][0] == data[i].title){
                                dataArray[k][1] += 1;
                                break;
                            }
                        }
                    }
                }
            }
            dataArray.sort(function(a,b){if(a == null){
                return 1;
            }
            else if (b == null){
                return -1;
            }
            return b[1] - a[1]});
            let sortedArray = new Array();
            for(let i = 0; i < dataArray.length; i++){
                if(dataArray[i][0] != null){
                    sortedArray[i] = dataArray[i];
                }
            }
            const parentElement = document.getElementById("main");
            const para = document.createElement("div");
            const node = document.createTextNode("Here are your most watched videos:");
            para.appendChild(node);
            let items = [];
            for(let i = 0; i < sortedArray.length; i++){
                items[items.length] = {text: "Title: " + sortedArray[i][0].substring(8) + ". Creator: " + sortedArray[i][3] + ". Times Watched: " + sortedArray[i][1] + ".", id: items.length};
                // let video = document.createElement("div");
                // let videoLink = document.createElement("a");
                // videoLink.href = sortedArray[i][2];
                // let videoText = document.createTextNode("Title: " + sortedArray[i][0].substring(8) + ". Creator: " + sortedArray[i][3] + ". Times Watched: " + sortedArray[i][1] + ".");
                // videoLink.appendChild(videoText);
                // // let videoThumbnail = document.createElement("img");
                // // videoThumbnail.src = "https://img.youtube.com/vi/" + sortedArray[i][2].substring(sortedArray[i][2].indexOf('=') + 1) + "/hqdefault.jpg";
                // // video.appendChild(videoThumbnail);
                // video.appendChild(videoLink);
                // para.appendChild(video);
            }
            // para.id = "videos";
            // parentElement.appendChild(para);
            setVideoItems(items)
        }
    }


    let file;
    document.body.style.overflow = "auto";
    document.body.style.backgroundColor = "white";
    return(
        <div id="ythr-main">
            <div id="main" >
                <div className="howTo">How to use: Upload your watch-history.json file.</div>
                <div className="howTo">Where to find your watch-history.json file:</div>
                <div className="howTo"><a href="https://takeout.google.com">Go to takeout.google.com</a></div>
                <div className="howTo">Select "Youtube and Youtube Music"</div>
                <div className="howTo">Click the button that says "All Youtube Data Selected"</div>
                <div className="howTo">Select only history (unless you want more data)</div>
                <div className="howTo">Click the button that says "Multiple Formats"</div>
                <div className="howTo">Set history to export as JSON</div>
                <div className="howTo">Follow the rest of the export process</div>
                <div className="howTo">Wait for the file to be zipped and sent to you</div>
                <div className="howTo">Get watch-history.json from the zip and upload it</div>
            
            </div>
            <input hidden={hideMain} id="input" onChange={(target) => {file=target.target.files[0]; console.log(file)}} type="file"></input>
            <div></div>
            <div hidden={hideMain}>
                <Button hidden={hideMain} id="upload" sx={{ boxShadow: 3, backgroundColor: "#DDDDDD", borderColor:"#AEAEAE", borderWidth: "2px" }} onClick={() => showFile(file, gridItems, setGridItems)}>Upload</Button>
            </div>
            <div hidden={!creatorsEnabled}>
                <Grid id="mostWatched" sx={{backgroundColor: "#9ECCBE"}} container alignItems="stretch" className='v-creator-container'>
                {
                    gridItems.map(item => (
                    <Grid sx={{ boxShadow: 3, p: 3, backgroundColor:"white", borderRadius: "5px", margin: "5px"}}xs={12} item id="test" className="v-creator-item v-center v-border">
                        {item.text}
                    </Grid>
                    ))
                }
                </Grid>
            </div>
            
            <div hidden={!videosEnabled}>
                <Grid id="showVideos" sx={{backgroundColor: "#9ECCBE"}} container alignItems="stretch" className='v-creator-container'>
                {
                    videoItems.map(item => (
                    <Grid sx={{ boxShadow: 3, p: 3, backgroundColor:"white", borderRadius: "5px", margin: "5px"}}xs={12} item id="test" className="v-creator-item v-center v-border">
                        {item.text}
                    </Grid>
                    ))
                }
                </Grid>
            </div>
        </div>
        )
}