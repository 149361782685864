import './styles.css';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

function showTime(){
    var date = new Date();
    var h = date.getHours();
    var m = date.getMinutes();
    var session = "AM";
    
    if(h == 0){
        h = 12;
    }
    
    if(h > 12){
        h = h - 12;
        session = "PM";
    }
    
    h = (h < 10) ? "0" + h : h;
    m = (m < 10) ? "0" + m : m;
    
    var time = h + ":" + m + " " + session;
    if(document.getElementById("clock")){
      document.getElementById("clock").innerText = time;
      document.getElementById("clock").textContent = time;
    }
    
    setTimeout(showTime, 1000);
    
}

function openStart(){
  var startButton = document.getElementById("start-button");
  startButton.hidden = true;
  var startButtonOpen = document.getElementById("start-button-open");
  startButtonOpen.hidden = false;
}

function closeStart(){
  var startButton = document.getElementById("start-button");
  startButton.hidden = false;
  var startButtonOpen = document.getElementById("start-button-open");
  startButtonOpen.hidden = true;
  
}

function openSocials(){
  var socials = document.getElementById("socials-window");
  var taskbarSocials = document.getElementById("socials-taskbar");
  if(socials){
    socials.hidden = false;
    taskbarSocials.hidden = false;
  }
  return;
}

function closeSocials(){
  var socials = document.getElementById("socials-window");
  var taskbarSocials = document.getElementById("socials-taskbar");
  if(socials){
    socials.hidden = true;
    taskbarSocials.hidden = true;
  }
  return;
}

function shutdown(){
  let element = document.getElementById("page")
  if(element){
    for(let j = 0; j < element.children.length; j++){
      var op = 1;  // initial opacity
      var timer = setInterval(async function () {
      if (op <= 0.001){
          clearInterval(timer);
          element.children[j].style.display = 'none';
      }
      element.children[j].style.opacity = op;
      element.children[j].style.filter = 'alpha(opacity=' + op * 10 + ")";
      op -= op * 0.005;
      await new Promise(r => setTimeout(r, 300));
  }, 10);
      console.log("element: " + element.children[j]);
    }
  }
}

export default function Main() {
  const [start, setStart] = useState(false);
  const [socialsEnabled, setSocials] = useState(false);

  document.body.style.overflow = "hidden";
  document.body.style.backgroundColor = "black";
  
  
    return(
            <div id="page">

                <div id="socials">
                    <div id="socials-icon">
                        <div id="icon-image">
                            <img id="socials-image" src={require("./socials-icon.png")}></img>
                        </div>
                        <div id="socials-text">
                            socials
                        </div>
                    </div>
                    <button id="socials-button" onClick={()=>{setSocials(true); openSocials()}}></button>
                </div>

                <div id="ythr">
                    <div id="ythr-icon">
                        <div id="icon-image">
                            <img id="ythr-image" src={require("./ythr.png")}></img>
                        </div>
                        <div id="ythr-text">
                            youtube history reader
                        </div>
                    </div>
                    <Link to="/ythr">
                        <button id="ythr-button"></button>
                    </Link>
                </div>

                <div id="socials-window" hidden={!socialsEnabled}>
                  <button id="close-socials" onClick={() => {closeSocials()}}></button>
                  <img id="socials-popup" src={require("./socialpopup.png")} ></img>
                  <div id="socials-window-area">
                    <div id="socials-window-text">my socials</div>
                    <a id="social-link" href='https://www.twitter.com/JephOnSteam' target="_blank">twitter</a>
                    <div></div>
                    <a id="social-link" href='https://www.steamcommunity.com/id/JephAllLuckNoSkill'target="_blank">steam</a>
                    <div></div>
                    <a id="social-link" href='https://www.youtube.com/@JephOnSteam'target="_blank">youtube</a>
                    <div></div>
                    <a id="social-link" href='https://github.com/madelinelily'target="_blank">github</a>
                  </div>
                </div>

                <img id="background" src={require("./bliss.png")}></img>

                <div id="taskbar">
                  <Button disableRipple id="start-button" onClick={()=>{setStart(true); openStart()}}></Button>
                  <img id="task-bar" src={require("./main_page/taskbar.png")}></img>
                  <img id="clock-space" src={require("./main_page/clock.png")}></img>
                  <div id="clock" className="clock" onLoad={showTime()}></div>
                  <img hidden={!socialsEnabled} id="socials-taskbar" src={require("./tlw-taskbar-socials.png")}></img>
                </div>

                <button id="close-start" onClick={()=>{setStart(false); closeStart()}} hidden={!start}></button>

                <div id="start-menu" hidden={!start}>
                  <img id="start-menu-image" src={require("./main_page/start-menu.png")}></img>
                  <Button disableRipple id="start-button-open" onClick={()=>{setStart(false); closeStart()}}></Button>
                  <button id="start-menu-cover" disabled={true}></button>
                  <button id="shutdown" onClick={()=>{shutdown()}}></button>
                </div>
                
            </div>
    )
}
